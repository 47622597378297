import type { WatchStopHandle } from 'vue';
import { ref, watch } from 'vue';
import { entityRepositoryPlugin } from '@/features/core/entity-repository';
import { Order } from '@/features/orders';
import type { DeliveryUnit } from '@/features/delivery-unit';
import { LABEL_FOR_MANUALLY_SCAN } from '@/features/handover-preparation/types';
import type { UseBagsCollection } from '../types';

export function useBagsCollection(): UseBagsCollection {
  const order = ref<Order | undefined>();
  let watcher: WatchStopHandle | null = null;
  const deliveryUnits = ref<DeliveryUnit[] | undefined>();

  const loadOrder = async (id: string): Promise<void> => {
    const result = await entityRepositoryPlugin.get().getById(Order, {
      id,
    });
    order.value = result.value;
    deliveryUnits.value = order.value?.deliveryUnits.sort(function (a, b) {
      if (b.position === LABEL_FOR_MANUALLY_SCAN) return -1;
      return 0;
    });

    // TODO: Remove watcher. Order didn't have to be reactive, because the only changes can come from this page.
    if (watcher) {
      watcher();
      watcher = null;
    }

    watcher = watch(result, () => {
      order.value = result.value;
    });
  };

  return {
    order,
    loadOrder,
    deliveryUnits,
  };
}
