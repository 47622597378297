import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/scan_bags.svg'


const _hoisted_1 = { class: "hero" }
const _hoisted_2 = { class: "hero__title" }
const _hoisted_3 = { "data-e2e": "order-code-label" }
const _hoisted_4 = {
  class: "hero__code",
  "data-e2e": "order-code-value"
}
const _hoisted_5 = { class: "hero__subtitle" }
const _hoisted_6 = {
  class: "scan-bag__button",
  "data-e2e": "bag-screen-scan-button"
}
const _hoisted_7 = {
  key: 0,
  class: "bags-list"
}

import { $t } from '@/i18n';
import type { ComponentPublicInstance, PropType, Ref } from 'vue';
import { computed, onBeforeMount, ref, toRefs, watch } from 'vue';
import { vueBarcodePlugin } from '@/features/barcode';
import {
  notificationPlugin,
  NotificationType,
} from '@/features/core/notifications';
import type {
  DeliveryUnit,
  DeliveryUnitPositions,
} from '@/features/delivery-unit';
import type { Order } from '@/features/orders';
import { BagWithPosition } from '@/features/ui';
import BarcodeScannerIcon from '@/features/ui/icons/BarcodeScannerIcon.vue';
import DeliveryUnitsManualScanning from './DeliveryUnitsManualScanning.vue';
import { DEFAULT_MANUALLY_SCAN, LABEL_FOR_MANUALLY_SCAN } from '../types';
import { initializeDeliveryUnitPositions } from '../';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeliveryUnitsSection',
  props: {
  deliveryUnits: {
    type: Array as PropType<DeliveryUnit>,
    required: true,
  },
  order: { type: Object as PropType<Order>, required: true },
},
  emits: ['onAllItemsPicked'],
  setup(__props, { emit: __emit }) {

// FIXME: Fix Type Errors for BagWithPosition above and code below
const props = __props;
const emit = __emit;
const {
  deliveryUnits,
  order,
}: {
  deliveryUnits: Ref<DeliveryUnit[]>;
  order: Ref<Order>;
} = toRefs(props);

const deliveryUnitCodes = computed(() =>
  deliveryUnits.value.reduce(
    (acc, currentUnit) => ((acc[currentUnit.code] = currentUnit.position), acc),
    {},
  ),
);

const positionsRefs: Ref<{ [key: string]: typeof DeliveryUnit }> = ref({});

const setPositionRefs = (element: typeof DeliveryUnit, key: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  positionsRefs.value[`position-${key}`] = element;
};

const titleByUnitPosition = (key: string) => {
  return key === LABEL_FOR_MANUALLY_SCAN
    ? $t('pages.scan-bag-position.manually-placed.text')
    : key;
};

const triggerManualSaving = (
  deliveryUnitPositionsList: DeliveryUnitPositions,
) => {
  const checkedDeliveryUnitPositionList = Object.keys(
    deliveryUnitPositionsList,
  ).filter(
    (key) =>
      deliveryUnitPositionsList[key].totalCount ===
      deliveryUnitPositionsList[key].scannedBarcode.length,
  );
  checkedDeliveryUnitPositionList.forEach((item) => {
    deliveryUnitPositions.value[item].scannedBarcode =
      deliveryUnitPositionsList[item].scannedBarcode;
  });
};

const deliveryUnitPositions: Ref<DeliveryUnitPositions> =
  ref<DeliveryUnitPositions>({});

onBeforeMount(() => {
  deliveryUnitPositions.value = initializeDeliveryUnitPositions(
    deliveryUnits.value,
  );
});

const notScannedBagCount = computed(() => {
  return Object.values(deliveryUnitPositions.value).filter((item) => {
    return item.totalCount !== item.scannedBarcode.length;
  }).length;
});

watch(notScannedBagCount, () => {
  emit('onAllItemsPicked', notScannedBagCount.value === 0);
});

const barcodeService = vueBarcodePlugin.get();

let scrollTimeout = null;
barcodeService.onBarcode({
  next(bagQrCode: string) {
    clearTimeout(scrollTimeout);
    if (bagQrCode in deliveryUnitCodes.value) {
      const currentCodePosition = deliveryUnitCodes.value[bagQrCode] as string;
      if (
        deliveryUnitPositions.value[
          currentCodePosition
        ]?.scannedBarcode.includes(bagQrCode) ||
        deliveryUnitPositions.value[
          currentCodePosition
        ]?.scannedBarcode.includes(DEFAULT_MANUALLY_SCAN)
      ) {
        notificationPlugin.get().show({
          text: $t('errors.barcode.already-scanned.message'),
          type: NotificationType.Error,
        });
        return;
      } else {
        deliveryUnitPositions.value[currentCodePosition]?.scannedBarcode.push(
          bagQrCode,
        );
        scrollTimeout = setTimeout(() => {
          const position = positionsRefs.value[
            `position-${currentCodePosition}`
          ] as ComponentPublicInstance;

          if (position) {
            // scroll by one position if next el under fixed footer
            if (
              (position.$el as HTMLElement).offsetTop >
              window.innerHeight - 200
            ) {
              window.scrollBy({
                top: 60,
                behavior: 'smooth',
              });
            }
          }
        }, 600);
      }
    } else {
      notificationPlugin.get().show({
        text: $t('errors.barcode.wrong-bag-item.message'),
        type: NotificationType.Error,
      });
    }
  },
  validationError() {
    notificationPlugin.get().show({
      text: $t('errors.barcode.cant-scan-position.message'),
      type: NotificationType.Error,
    });
  },
  pattern: 'BagsScanCollectionRegexp',
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("section", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "hero__image" }, [
        _createElementVNode("picture", null, [
          _createElementVNode("img", {
            alt: "",
            class: "ut-img-contain",
            "data-e2e": "order-image",
            src: _imports_0
          })
        ])
      ], -1)),
      _createElementVNode("h1", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref($t)('pages.bags-collection.title-order-code-bag-label.text')), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(order)?.pickupCode), 1)
      ]),
      _createElementVNode("h3", _hoisted_5, _toDisplayString(_unref(order)?.customer.lastName), 1),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(BarcodeScannerIcon, {
          height: "24",
          width: "24"
        }),
        _createTextVNode(" " + _toDisplayString(_unref($t)('pages.bags-collection.scan-bag-items.text')), 1)
      ])
    ]),
    (deliveryUnitPositions.value)
      ? (_openBlock(), _createElementBlock("section", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(deliveryUnitPositions.value, (item, key) => {
            return (_openBlock(), _createBlock(_unref(BagWithPosition), {
              key: key,
              ref_for: true,
              ref: (el) => setPositionRefs(el, key),
              checked: item.totalCount === item.scannedBarcode.length,
              "delivery-unit": item,
              disabled: true,
              "manually-placed": key === _unref(LABEL_FOR_MANUALLY_SCAN),
              position: key,
              title: titleByUnitPosition(key)
            }, null, 8, ["checked", "delivery-unit", "manually-placed", "position", "title"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createVNode(DeliveryUnitsManualScanning, {
      deliveryUnitPositions: deliveryUnitPositions.value,
      disabled: notScannedBagCount.value === 0,
      onApply: triggerManualSaving
    }, null, 8, ["deliveryUnitPositions", "disabled"])
  ], 64))
}
}

})